html body {
    padding: 0;
}

.print {
    display: none !important;
}

@media print {
    .no-print,
    [role="alertdialog"],
    [role="tooltip"] {
        display: none !important;
    }

    .print {
        display: initial !important;
    }

    .page-break-after {
        display: block;
        page-break-before: always;
    }

    .print-background {
        -webkit-print-color-adjust: exact !important;
    }

    @page
    {
        size:  auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
    }

    html
    {
        margin: 0 20px;  /* this affects the margin on the html before sending to printer */
    }

    @media print {
        html {
            margin: 0;
        }
    }
}

.print-page-header, .print-page-header-space {
  height: 60px;
}

.print-page-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-right: 44px;
  padding-left: 44px;
}

.print-page {
    page-break-after: always;
}

